import { useTranslation } from "react-i18next";
import Hero from "../../components/Hero/Hero";
import hero from "./../../static/images/rental-management/hero.jpeg";
import CTABlock from "../../components/CTABlock/CTABlock";
import Grid from "../../components/Grid/Grid";
import forWhom from "./../../static/images/rental-management/for-whom.jpeg";
import useRentalManagementAccordionServices from "../../static/content/RentalManagement/RentalManagementAccordionServices";
import Accordion from "../../components/Accordion/Accordion";
import Highlights from "../../components/Highlights/Highlights";
import useHomeHighlights from "../../static/content/HomeContent/HomeHighlights";
import imgFormula from './../../static/images/rental-management/formula.jpeg';
import FormContainer from "../../components/FormContainer/FormContainer";
import useRentalManagementAccordionFAQs from '../../static/content/RentalManagement/RentalManagementAccordionFAQs';
import Incentive from "../../components/Incentive/Incentive";
import TextBlock from "../../components/TextBlock/TextBlock";
import DocumentMeta from "../../components/DocumentMeta/DocumentMeta";

const RentalManagement = () => {
    DocumentMeta("Short Term Rental Management Services in Marbella - Premavista", "Premavista offers comprehensive short-term rental management services in Marbella. From listing optimization to guest communication, we handle everything to ensure your property is a top performer on platforms like Airbnb and Booking.com.")
    const { t } = useTranslation("common");

    const accordionItemsServices = useRentalManagementAccordionServices();
    const highlights = useHomeHighlights();
    const accordionItemsFAQs = useRentalManagementAccordionFAQs();

    return (
        <>
            <Hero heroImg={hero} heading1={t('rental-management.h1')} />
            <CTABlock heading2={t('rental-management.ctablock-1-h2')} text={t('rental-management.ctablock-1-text')} />
            <Grid reverse={true} imgSrc={forWhom} imgAlt={t('rental-management.grid-1-img-alt')} heading3={t('rental-management.grid-1-h3')} text={t('rental-management.grid-1-text')} />
            <Accordion heading2={t('rental-management.accordion-1-h2')} accordionItems={accordionItemsServices}/>
            <Highlights heading2={t('home.highlights-h2')} highlights={highlights} />
            <TextBlock heading2={t('rental-management.text-block-1-h2')} text={t('rental-management.text-block-1-text')} />
            <Grid background={true} imgSrc={imgFormula} imgAlt={t('home.grid-3-img-alt')} altTextStyle={true} text={t('rental-management.formula-text')} span1Text={t('rental-management.formula-span-1')} span2Text={t('rental-management.formula-span-2')} />
            <FormContainer backgroundImg={hero} heading3={t('rental-management.form-h3')} templateID='template_f3eob18' />
            <Accordion heading2={t('rental-management.accordion-2-h2')} accordionItems={accordionItemsFAQs} />
            <Incentive heading2={t('rental-management.incentive-1-h2')} incentiveCTAText={t('rental-management.incentive-1-ctatext')} incentiveCTAHref='/contact-us' />
        </>
    )
}

export default RentalManagement;