import './TextBlock.scss';
import { Trans } from 'react-i18next';

const TextBlock = ({ heading2, text }) => {
    return (
        <div className="container">
            <h2>{heading2}</h2>
            <Trans><p>{text}</p></Trans>
        </div>
    )
}

export default TextBlock;