import './CTABlock.scss';
import CTA from '../CTA/CTA';
import { Trans } from 'react-i18next';

const CTABlock = ({ heading2, text, ctaText, ctaHref}) => {
    return (
        <div className="container">
            <div className="cta-block flex">
                <div className="cta-block__heading flex flex__col--6">
                    <Trans><h2>{heading2}</h2></Trans>
                </div>
                <div className="cta-block__text flex flex__col--6">
                <Trans>
                    {text && <p>{text}</p>}
                    {(ctaText && ctaHref) && <CTA ctaText={ctaText} ctaHref={ctaHref} />}
                </Trans>
                </div>
                
            </div>
        </div>
    )
}

export default CTABlock;