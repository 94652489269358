import './Grid.scss';
import CTA from '../CTA/CTA';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';

const Grid = ({ reverse, background, heading2, imgSrc, imgAlt, href, linkText, heading3, text, altTextStyle, span1Text, span2Text,ctaText, ctaHref}) => {
    return (
        <div className={`${background ? "background" : ""}`}>
            <div className="container">
                {heading2 && <Trans><h2>{heading2}</h2></Trans>}
                <div className={`grid flex ${reverse ? "flex--reverse" : ""}`}>
                    <div className="grid-img flex flex__col--6">
                       <img src={imgSrc} alt={imgAlt} /> 
                        {href && linkText && <div className="link-wrapper"><Link to={href}>{linkText}</Link> <div className="line"></div></div>}
                    </div>
                    <div className="grid-text flex flex__col--6">
                        <div className={`${altTextStyle ? "handwriting" : ""}`}>
                            {heading3 && <h3>{heading3}</h3>}
                            <Trans><p>{text}</p></Trans>
                            {ctaText && ctaHref && <CTA ctaText={ctaText} ctaHref={ctaHref}/>}
                            {span1Text && <span>{span1Text}</span>}
                            {span2Text && <span>{span2Text}</span>}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Grid;